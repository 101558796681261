import { isBoolean } from 'element-plus/es/utils'
import { apiRootUrl, loginPath } from '../config'
import store from '../store'
import { ElMessage, ElMessageBox } from 'element-plus'

// 树的基本结构
export interface BaseTree {
  children?: BaseTree[]
  id: string,
  label: string
}

// 菜单结构
export interface MenuTree {
  label: string
  tag: string
  children?: MenuTree[]

  id: string
  icon: string
  url: string // 管理员菜单使用
  bindPage: string, // 管理员菜单使用
  isAddNewMenu: boolean, // 编辑模式时是否添加的新菜单
  isDefaultOpen: boolean // 是否展开
}
// 获取默认打开的菜单id
export function getOpenMenuIds (menus: MenuTree[]): string[] {
  let result = [] as string[]
  // eslint-disable-next-line
  for (const [key, item] of Object.entries(menus)) {
    if (item.isDefaultOpen) {
      result.push(item.id)
    }
    if (!isEmptyArray(item.children)) {
      // eslint-disable-next-line
      result = [...result, ...getOpenMenuIds(item.children!)]
    }
  }
  return result
}
// 查找到数的节点的返回数据
export class NodeFullPathInfo {
  id: string
  fullPath: string
  depath: number
  node: BaseTree

  constructor (id: string, fullPath: string, depath: number, node: BaseTree) {
    this.id = id
    this.fullPath = fullPath
    this.depath = depath
    this.node = node
  }
}
// 查找 id 对应节点的全路径，及 深度
export function findTreeNode (data: BaseTree[] | BaseTree, targetId: string, path: string, depth: number): NodeFullPathInfo | null {
  if (Array.isArray(data)) {
    // eslint-disable-next-line
    for (const [key, item] of Object.entries(data)) {
      if (item.id === targetId) {
        return new NodeFullPathInfo(item.id, path + '/' + getI18n(item.label, false), depth, item)
      }
      if (!isEmptyArray(item.children)) {
        const result = findTreeNode(item.children as BaseTree[], targetId, path + '/' + getI18n(item.label, false), depth + 1)
        if (result !== null) {
          return result
        }
      }
    }
  } else {
    if (!isEmptyArray(data.children)) {
      return findTreeNode(data.children as BaseTree[], targetId, path + '/' + getI18n(data.label, false), depth + 1)
    }
  }
  return null
}

// 翻译 树形数据的节点
export function i18nTreeData (data: BaseTree[] | BaseTree) {
  console.log('i18nTreeData', data)
  if (Array.isArray(data)) {
    // eslint-disable-next-line
    for (const [key, item] of Object.entries(data)) {
      console.log('item.label=', item.label)
      item.label = getI18n(item.label, false)
      console.log('item.label=', item.label)
      console.log('----------------------------')
      if (!isEmptyArray(item.children)) {
        i18nTreeData(item.children as BaseTree[])
      }
    }
  } else {
    if (!isEmptyArray(data.children)) {
      i18nTreeData(data.children as BaseTree[])
    }
  }
}

// 是否是纯数字字符串
export function isNumeric (input: string): boolean {
  const pattern = /^\d+$/
  return pattern.test(input)
}

// 树形结构中找到最大的id
export function getMaxId (data: BaseTree[] | BaseTree): number {
  // 查找 id 对应节点的全路径，及 深度
  function findMaxId (data: BaseTree[]): string {
    let maxId = ''
    // eslint-disable-next-line
    for (let i = 0; i < data.length; i++) {
      if (!isNullValue(data[i].id)) {
        if (isNumeric(data[i].id) && maxId < data[i].id) {
          maxId = data[i].id
        }
      }
      if (!isEmptyArray(data[i].children)) {
        const fMaxId = findMaxId(data[i].children as [BaseTree])
        if (maxId < fMaxId) {
          maxId = fMaxId
        }
      }
    }
    return maxId
  }
  let maxId = ''
  if (Array.isArray(data)) {
    maxId = findMaxId(data)
  } else {
    if (!isEmptyArray(data.children)) {
      maxId = findMaxId(data.children as BaseTree[])
    }
  }

  if (maxId === '') {
    return 10000
  }
  return Number(maxId)
}
// 填充为空的id
export function fillNullId (data: BaseTree[] | BaseTree) {
  // 给没有 组件实例 id 的实例， 设置id

  // 使用 时间戳 ，而不是顺序递增的id了
  // let curId = getMaxId(data)
  function setNullId (data: BaseTree[] | BaseTree, depth: number) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (isNullValue(data[i].id)) {
          // data[i].id = String(++curId)
          data[i].id = generateIdByTimestamp()
        }
        // eslint-disable-next-line
        if (!isEmptyArray(data[i].children)) {
          // 递归重新组织 子菜单
          setNullId(data[i].children as BaseTree[], depth + 1)
        }
      }
    } else {
      if (isNullValue(data.id)) {
        // data.id = String(++curId)
        data.id = generateIdByTimestamp()
      }
      if (!isEmptyArray(data.children)) {
        // 递归重新组织 子菜单
        setNullId(data.children as BaseTree[], depth + 1)
      }
    }
  }
  setNullId(data, 0)
  // return curId
}
// fetch 获取数据
export async function apiFetchData (path: string) {
  const url = apiRootUrl + path
  console.log('apiFetchData url=', url)
  const response = await fetch(url)
  const jsonData = await response.json()
  if (jsonData.errcode === 'unauthorized') {
    window.location.href = loginPath
    throw new Error('跳转到登录页') // 抛出异常中断逻辑
  }
  return jsonData
}

// 保存数据
export async function apiPostData (path: string, data: object) {
  console.log('apiPostData path=' + path)

  let url = path
  if ((path.startsWith('http://') || path.startsWith('https://'))) {
    data = Object.assign(data, { authorizationToken: store.state.authorizationToken })
  } else {
    url = apiRootUrl + path
  }
  console.log('apiPostData url=' + url)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(function (data) {
      console.log(data)
      if (data.errcode === 'unauthorized') {
        window.location.href = loginPath
        // throw new Error('跳转到登录页') // 抛出异常中断逻辑
      } else if (data.errcode === 'redirect_home') {
        window.location.href = '/'
        // throw new Error('跳转到到首页') // 管理员用户时会执行到这里
      }
      return data
    })
    .catch(error => console.log('Error:', error))
}

// 深度复制
// eslint-disable-next-line
export function deepCopy (obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // eslint-disable-next-line
  const copy: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key])
    }
  }

  return copy
}

// 拖拽时的原始事件数据结构， 依据需求逐渐扩展
export interface OriginalEvent {
  clientX: number,
  clientY: number
}

// 拖拽事件
export interface DraggableEvent {
  clone: HTMLElement,
  from: HTMLElement,
  isTrusted: boolean,
  item: HTMLElement,
  newDraggableIndex: number,
  oldIndex: number,
  originalEvent: OriginalEvent,
  pullMode: string,
  to: HTMLElement,
  bubbles: boolean,
  cancelBubble: boolean,
  cancelable: boolean,
  composed: boolean,
  currentTarget: HTMLElement,
  defaultPrevented: boolean,
  eventPhase: object,
  detail: number,
  returnValue: boolean,
  srcElement: HTMLElement,
  target: HTMLElement,
  timeStamp: number,
  type: string
}

// 递归向上查找 html 元素
export function findParentWithAttribute (element: HTMLElement|null, attrName: string, attrValue: string|null) : HTMLElement | null {
  if (!element || !attrName) {
    return null
  }

  // 检查当前元素是否具有指定的属性和值
  if ((attrValue !== null && element.getAttribute(attrName) === attrValue) || (attrValue === null && element.getAttribute(attrName) !== null)) {
    return element
  }

  // 递归向上查找父元素
  return findParentWithAttribute(element.parentElement, attrName, attrValue)
}

// eslint-disable-next-line
export function isNullValue (value: any | null): boolean {
  return value === undefined || value === null
}

// eslint-disable-next-line
export function isEmptyArray (arr: any | null): boolean {
  return arr === undefined || arr === null || arr.length === 0
}

export function isEmptyString (value: string | null): boolean {
  return value === undefined || value === null || value === ''
}

export function stringEqual (value: string | null, tagValue: string): boolean {
  return value !== undefined && value === tagValue
}

// eslint-disable-next-line
export function isTrue (arr: any | null): boolean {
  return arr !== undefined && !!arr
}

export interface TableItemEvent {
  label: string // 按钮显示的名字
  targetUrl: string // 按钮绑定的服务地址
  isShowEditDlg: boolean // 是否显示编辑窗口
  editDlgTitle: string // 编辑窗口标题
  editOkBtnName: string // 确定按钮名称
  isConfirm: boolean // 是否显示确认窗口
  fieldName: string // 关联字段
  fieldValue: string // 当字段等于这个值时，才显示按钮
  isNewEvent: boolean // 是否是添加新事件
  isExpand: boolean // 是否展开
}

export interface TableItemEvents {
  eventList: TableItemEvent[]
}

export function parseCookieContent (content:string): string {
  return content.replace(/\\([0-7]{3})/g, (_, octal) => {
    // 将八进制数转换为相应的字符
    return String.fromCharCode(parseInt(octal, 8))
  })
}

export function getCookie (name: string): string {
  const cookieName = name + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      const content = cookie.substring(cookieName.length, cookie.length)
      console.log('cookie:', name, ':', content)
      return decodeURIComponent(content)
      // return parseCookieContent(content)
    }
  }
  return ''
}

export function getI18n (content: string, output = false): string {
  if (output) {
    console.log(content)
  }

  if (store.state.language.startsWith('zh')) {
    if (output) {
      console.log('return content')
    }
    return content
  }
  if (content in store.state.i18n) {
    if (output) {
      console.log('return store.state.i18n[content]', store.state.i18n[content])
    }
    return store.state.i18n[content]
  }
  if (output) {
    console.log('last return content')
  }
  return content
}
export function getParameterByName (name: string, url: string): string | null {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getLanguage ():string {
  let language = getParameterByName('language', window.location.href)
  console.log('param language=', language)
  if (isEmptyString(language)) {
    language = navigator.language
  }
  // eslint-disable-next-line
  return language!
}

export function eval2 (code:string) {
  const func = new Function('return ' + code)
  const result = func()
  return result
}

export class RequestResult {
  success: boolean
  message: string

  constructor () {
    this.success = false
    this.message = ''
  }
}
export function parseResponse (data: object):RequestResult {
  console.log('parseResponse ', data)
  const result = new RequestResult()
  if (data === undefined || data === null) {
    result.success = false
    result.message = 'network error'
    console.error('network error')
    return result
  } else {
    result.success = (data as {success: boolean}).success
    if (result.success === undefined || result.success === null || result.success === true) {
      // undefined 时， 返回的事正常数据，默认成功
      result.success = true
      result.message = '成功'
      return result
    }
  }
  const errmsg = (data as {errmsg: string}).errmsg
  if (!isEmptyString(errmsg)) {
    result.message = errmsg
    return result
  }
  const message = (data as {message: string}).message
  if (!isEmptyString(message)) {
    result.message = message
    return result
  }
  result.message = message
  return result
}

type MessageBoxCallbackType = (result: any) => void; // 定义一个函数类型
export function showRequestResult (data:object, prefixOk:string, prefixFail:string, messageBoxtitle = '请求结果',
  errorIsMessageBox = true, succesPopMessage = true, succesIsMessageBox = false, callback:MessageBoxCallbackType):RequestResult {
  prefixOk = getI18n(prefixOk)
  prefixFail = getI18n(prefixFail)
  messageBoxtitle = getI18n(messageBoxtitle)

  const result = parseResponse(data)
  if (!succesPopMessage) {
    return result
  } else if (errorIsMessageBox && !result.success) {
    ElMessageBox.alert(getI18n(prefixFail) + ' ' + getI18n(result.message), getI18n(messageBoxtitle), {
      confirmButtonText: getI18n('确定')
    }).then(() => {
      console.log('callback=', callback)
      if (callback != null && callback !== undefined) {
        (callback as MessageBoxCallbackType)(result)
      }
    })
  } else if (succesIsMessageBox) {
    ElMessageBox.alert(getI18n(prefixOk) + ' ' + getI18n(result.message), getI18n(messageBoxtitle), {
      confirmButtonText: getI18n('确定')
    }).then(() => {
      console.log('callback=', callback)
      if (callback != null && callback !== undefined) {
        (callback as MessageBoxCallbackType)(result)
      }
    })
  } else {
    ElMessage({
      type: result.success ? 'success' : 'error',
      message: result.success ? getI18n(prefixOk) + ':' + getI18n('成功') : getI18n(prefixFail) + ':' + getI18n(result.message)
    })
  }

  return result
}

export function generateIdByTimestamp (): string {
  const timeStamp = Date.now()
  if (timeStamp === store.state.currentTimestamp) {
    store.state.currentTimestampIndex++
  }
  store.state.currentTimestamp = timeStamp
  return String(timeStamp) + store.state.currentTimestampIndex.toString().padStart(5, '0')
}

export function isAdmin (): boolean {
  let roleListStr = getCookie('roleList')
  if (roleListStr.startsWith('"')) {
    roleListStr = roleListStr.substring(1)
  }
  if (roleListStr.endsWith('"')) {
    roleListStr = roleListStr.substring(0, roleListStr.length - 1)
  }
  const roleArray = roleListStr.split(',')

  // 使用 includes() 方法判断某个值是否在数组中
  return roleArray.includes('developer')
}

export function decodeHTMLEntities (text: string) {
  const parser = new DOMParser()
  const decodedString = parser.parseFromString(text, 'text/html').documentElement.textContent
  return decodedString
}
