<template>
  <!-- 查询区 -->
  <el-row :gutter="12" class="demo-radius" align="middle">
    <template v-for="(col2) in tableAttr.columns" :key="col2.prop">
      <template v-if=(isTrue(col2.isQuery))>
        <el-col :span="4" align="right" style="margin-bottom:10px;">
          <el-text class="mx-1">{{getI18n(getLabel(col2.prop))}}:</el-text>
        </el-col>
        <el-col :span="4" style="margin-bottom:10px;">
          <component :is="col2.queryTag" :name="col2.prop" class="param" v-model="refQueryParam[col2.prop]"></component>
        </el-col>
      </template>
    </template>
    <el-col :span="getButtonSpan()" align="right">
      <el-button type="primary" @click="queryData()" v-if="hasQueryArea()">{{getI18n('查询',true)}}</el-button>
      <el-button type="primary" @click="queryData()" v-if="!hasQueryArea()">{{getI18n('刷新',true)}}</el-button>
      <el-button type="primary" @click="reset()" v-if="hasQueryArea()">{{getI18n('重置')}}</el-button>
      <el-button type="primary" @click="addData()" v-if="isAddData">{{getI18n('添加')}}</el-button>
      <el-button type="primary" @click="queryData(true)" v-if="isDownloadData">{{getI18n('下载')}}</el-button>
    </el-col>
  </el-row>
  <!-- 数据表格 -->
  <el-table :data="refTableData" border style="width: 100%;"  @sort-change=sortChange align="left">
    <template v-for="(col) in tableAttr.columns" :key="col.prop">
      <template v-if="col.isNewCol===true">
        <!-- 新列不在主表中显示 -->
      </template>
      <!-- 展开区 -->
      <template v-else-if="col.isShow===true && col.columnType=='expand'">
        <el-table-column type="expand">
          <template #default="props">
            <div m="4">
              <template v-for="(col2) in tableAttr.columns" :key="col2">
                <template v-if="!col.isNewCol && col2.isExpand==true">
                  <p m="t-0 b-2">{{getI18n(col2.label)}}: {{props.row[col2.prop]}}</p>
                </template>
              </template>
            </div>
          </template>
        </el-table-column>
      </template>
      <!-- 操作 -->
      <template v-else-if="col.isShow===true && col.columnType=='action'">
        <el-table-column :label="getI18n(col.label)" :width="col.width">
          <template #default="scope">
            <el-row :gutter="20" justify="center" align = "middle">
              <template v-if="!isNullValue(col.actions)">
                  <!-- 编辑按钮 -->
                  <template v-if="isEmptyString(col.actions.editBtnName)">
                    <!-- 以图标方式显示编辑按钮 -->
                    <el-button size="small"
                      @click="handleEdit(scope.$index, scope.row)"
                      v-if="col.actions.edit===true"
                      style="margin-top:8px; margin-left:5px">
                    <el-icon><Edit /></el-icon></el-button>
                  </template>
                  <template v-else>
                    <!-- 以文字方式显示编辑按钮 -->
                    <el-button type="primary"
                      @click="handleEdit(scope.$index, scope.row)"
                      style="margin-top:8px; margin-left:5px">{{getI18n(col.actions.editBtnName)}}
                    </el-button>
                  </template>
                  <!-- 删除按钮 -->
                  <el-button size="small"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    v-if="col.actions.delete===true" style="margin-top:8px; margin-left:5px" ><el-icon><Delete /></el-icon>
                  </el-button>
              </template>
              <!-- 其他添加的事件按钮 -->
              <template v-for="(event, index) in itemEvents.eventList" :key="index">
                <el-button size="small"
                @click="customItemEvent(scope.$index, scope.row, event)"
                v-if="isShowBtn(event, scope.row)"
                style="margin-top:8px; margin-left:5px"
                >{{getI18n(event.label)}}</el-button>
              </template>
            </el-row>
          </template>
        </el-table-column>
      </template>
      <!-- 普通列 -->
      <template v-else>
        <el-table-column :prop="col.prop" :label="getI18n(col.label)" :width="col.width" :sortable="col.isSortable" v-if="col.isShow===true" />
      </template>
    </template>
  </el-table>
  <!-- 分页 -->
  <el-pagination
    v-model:current-page="refCurrentPage"
    v-model:page-size="refPageSize"
    :page-sizes="pageSizeList"
    layout="total, sizes, prev, pager, next, jumper"
    :total="refTotal"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    style="margin-top:10px;justify-content: center;"
  />
  <!-- 编辑数据 -->
  <TableDataDlg :data="rowData" :dialogIndex="dialogIndex" @onOk="onOk" @onCancel="onCancel" :destroy-on-close="true" v-if="refIsShowDlg"/>
  <!-- 编辑模式 -->
  <template v-if="isEditPageMode">
    <template v-if="isShowEditColWin">
      <!-- 编辑表格 -->
      <el-table :data="tableAttr.columns"  :expand-row-keys="refEditExpandRowKeys" row-key="id" border style="width: 100%" align="left">
        <!-- 属性扩展区 -->
        <el-table-column type="expand">
          <template #default="data">
            <el-row :gutter="12" class="demo-radius" align="middle" justify="start">
              <el-col :span="6">
              <el-checkbox v-model="data.row.isExpand" :disabled="!(data.row.columnType==='normal')" label="显示在扩展区" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.actions.edit" :disabled="!(data.row.columnType==='action')" label="编辑按钮" size="large" />
              </el-col>
              <el-col :span="6">
                <el-input v-model="data.row.actions.editBtnName" :disabled="!(data.row.columnType==='action')" :placeholder="'编辑按钮名称'" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.actions.delete" :disabled="!(data.row.columnType==='action')" label="删除按钮" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.isShowInAddDlg" :disabled="!(data.row.columnType==='normal')" label="是添加字段" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.isShowInEditDlg" :disabled="!(data.row.columnType==='normal')" label="显示在编辑窗口" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.disabledInEditDlg" :disabled="!(data.row.columnType==='normal')" label="在编辑窗口只读" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.isRequired" :disabled="!(data.row.columnType==='normal')" label="必填" size="large" />
              </el-col>
              <el-col :span="6">
                <el-input v-model="data.row.placeholder" :disabled="!(data.row.columnType==='normal')" :placeholder="'编辑框的placeholder'" />
              </el-col>
              <el-col :span="6">
                 <el-select
                  v-model="data.row.tagInEditDlg"
                  class="m-2"
                  placeholder="标签样式"
                  style="width: 100%"
                  :disabled="!(data.row.columnType==='normal')"
                >
                  <el-option
                    v-for="item in tagDefinedList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-input v-model="data.row.srcDataUrl" :disabled="!(data.row.columnType==='normal')" :placeholder="'下拉选择器绑定数据源'" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.allowCreate" :disabled="!(data.row.columnType==='normal')" label="下拉选择器是否允许创建" size="large" />
              </el-col>
              <el-col :span="6">
                <el-checkbox v-model="data.row.multiple" :disabled="!(data.row.columnType==='normal')" label="下拉选择器是否允许多选" size="large" />
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <!-- 每一列的属性 -->
        <el-table-column prop="prop" label="字段名" width="150">
          <template #default="data">
            <el-input v-model="data.row.prop" :disabled="data.row.columnType!='normal'" :placeholder="'数据字段'" />
          </template>
        </el-table-column>
        <el-table-column prop="lable" label="列名" width="120">
          <template #default="data">
            <el-input v-model="data.row.label" :placeholder="'列名'" />
          </template>
        </el-table-column>
        <el-table-column prop="width" label="列宽度" width="120">
          <template #default="data">
            <el-input v-model="data.row.width" :placeholder="'宽度'" />
          </template>
        </el-table-column>
        <el-table-column prop="type" label="列类型" width="120">
          <template #default="data">
            <el-select
              v-model="data.row.columnType"
              class="m-2"
              placeholder="数据类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in columType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="显示" width="75">
          <template #default="data">
            <el-switch
              v-model="data.row.isShow"
              style="--el-switch-on-color: #2196F3; --el-switch-off-color: #B0B0B0"
            />
          </template>
        </el-table-column>
        <el-table-column prop="isSortable" label="排序" width="75">
          <template #default="data">
            <template v-if="data.row.columnType==='normal'">
              <el-switch
                v-model="data.row.isSortable"
                style="--el-switch-on-color: #2196F3; --el-switch-off-color: #B0B0B0"
              />
            </template>
            <template v-else>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="isQuery" label="查询" width="75">
          <template #default="data">
            <template v-if="data.row.columnType==='normal'">
              <el-switch
                v-model="data.row.isQuery"
                style="--el-switch-on-color: #2196F3; --el-switch-off-color: #B0B0B0"
              />
            </template>
            <template v-else>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="queryTag" label="查询类型" width="120">
          <template #default="data">
            <el-select
              v-model="data.row.queryTag"
              class="m-2"
              placeholder="查询类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in tagDefinedList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="75">
          <template #default="data">
            <template v-if="data.row.isNewCol">
              <el-button type="success" @click="addColumn(data.$index)"><el-icon><Plus /></el-icon></el-button>
            </template>
            <template v-else>
              <el-button type="primary" @click="removeColumn(data.$index)"><el-icon><Delete /></el-icon></el-button>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="位置" width="0">
          <template #default="data">
            <el-row :gutter="12" class="demo-radius" align="middle">
              <el-col :span="12">
                <el-button type="primary" :disabled="data.$index===0" @click="moveUp(data.$index)"><el-icon><Top /></el-icon></el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" :disabled="data.$index===getColumnLength()-1" @click="moveDown(data.$index)"><el-icon><Bottom /></el-icon></el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <el-row>
    </el-row>
  </template>

</template>

<script lang="ts">
import { ref, defineComponent, defineProps, onMounted, watch } from 'vue'
import { apiFetchData, isEmptyString, isNullValue, isTrue, apiPostData, TableItemEvents, TableItemEvent, getI18n, showRequestResult, deepCopy } from '../utils'
import TableDataDlg from './TableDataDlg.vue'
import DropDownSelector from './DropDownSelector.vue'
import CascaderSelector from './CascaderSelector.vue'
import ImageItem from './ImageItem.vue'
import { ElMessageBox } from 'element-plus'
import { apiForwardPath, apiPgSelectPath, apiPgInsertPath, apiPgDeletePath, apiPgUpdatePath } from '../config'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'QueryTable',
  components: {
    TableDataDlg,
    DropDownSelector,
    CascaderSelector,
    ImageItem
  }
})
</script>

<script lang="ts" setup>
const props = defineProps({
  // 列
  tableAttr: {
    type: Object as () => TableAttr,
    require: true
  },
  // 获取数据的url
  fetchDataurl: {
    type: String,
    require: true
  },
  // 绑定的表
  tableName: {
    type: String,
    require: true
  },
  // 操作的目标数据源
  targetSourceId: {
    type: String,
    require: true
  },
  // 是否显示数据
  isShowData: {
    type: Boolean,
    require: true,
    default: true
  },
  // 是否本地化数据
  isI18n: {
    type: Boolean,
    require: true,
    default: true
  },
  // 每页数量的可选list
  pageSizeList: {
    type: Array as () => number[],
    default: () => [10, 20, 30, 40, 100]
  },
  // 当前设置的每页数量
  pageSize: {
    type: Number,
    default: 10
  },
  // 是否是页面编辑模式
  isEditPageMode: {
    type: Boolean,
    required: false,
    default: false
  },
  // 是否显示列编辑窗口
  isShowEditColWin: {
    type: Boolean,
    required: false,
    default: false
  },
  // 是否允许添加数据
  isAddData: {
    type: Boolean,
    required: false,
    default: false
  },
  // 是否允许下载数据
  isDownloadData: {
    type: Boolean,
    required: false,
    default: false
  },
  // 添加数据窗口的title
  addDlgTitle: {
    type: String,
    required: false,
    default: ''
  },
  // 编辑窗口的title
  editDlgTitle: {
    type: String,
    required: false,
    default: ''
  },
  // 编辑窗口的 确定 按钮名称
  editOkBtnName: {
    type: String,
    required: false,
    default: ''
  },
  // 编辑|添加窗口的宽度
  dialogWidth: {
    type: String,
    required: false,
    default: '800'
  },
  // 编辑|添加窗口的标签宽度
  formLabelWidth: {
    type: String,
    required: false,
    default: '140'
  },
  // 编辑窗口保存数据的url
  saveEditDataUrl: {
    type: String,
    required: false,
    default: ''
  },
  // 添加窗口保存数据的url
  saveNewDataUrl: {
    type: String,
    required: false,
    default: ''
  },
  // 删除数据的url
  deleteDataUrl: {
    type: String,
    required: false,
    default: ''
  },
  deleteDataConfirm: {
    type: Boolean,
    required: false,
    default: true
  },
  itemEvents: {
    type: Object as () => TableItemEvents,
    require: true
  }
})

// 总数据数（行数）
const refTotal = ref(0)

// 表格数据
const refTableData = ref([])

// 排序的列
const refProp = ref('')
// 排序方式
const refSortOrder = ref('')

// 每页数量
const refPageSize = ref(props.pageSize)
// 当前页
const refCurrentPage = ref(1)

// 查询条件
const refQueryParam = ref({} as {[key: string]: string})

// 类型定义开始
// 列类型
const columType = [
  {
    value: 'normal',
    label: '普通列'
  },
  {
    value: 'expand',
    label: '展开列'
  },
  {
    value: 'action',
    label: '功能列'
  }
]

// 查询区/添加/编辑的组件类型
const tagDefinedList = [
  {
    value: 'el-input',
    label: '文本框'
  },
  {
    value: 'DropDownSelector',
    label: '下来选择器'
  },
  {
    value: 'CascaderSelector',
    label: '多级选择器'
  },
  {
    value: 'el-switch',
    label: 'switch开关'
  },
  {
    value: 'ImageItem',
    label: '图片'
  }
]

// 表的排序事件
export interface SortChangeEvent {
  column: object
  prop: string
  order: string
}

export interface TableColumnAction {
  edit: boolean
  editBtnName: string
  delete: boolean
}

export interface TableColumnInfo {
  id: string // 每列的唯一标识，控制展开
  columnType: string // 列类行
  filedType: string // 字段类型
  prop: string // 字段名
  label: string // 列显示名称
  width: string // 宽度
  isShow: boolean, // 是否显示
  isSortable: boolean // 是否排序
  isQuery: boolean // 是否是查询条件
  queryTag: string // 查询组件类型
  isExpand: boolean // 是否在展开数据中
  actions: TableColumnAction // 操作按钮
  isNewCol: boolean // 是否是新列

  isShowInEditDlg: boolean // 是否出现在编辑窗口
  disabledInEditDlg: boolean // 在编辑窗口中是否不可编辑
  isShowInAddDlg: boolean // 是否出现在添加窗口

  tagInEditDlg: string // 在编辑窗口中对应的tag
  isRequired: boolean // 是否是必填字段
  placeholder: string // 占位符

  // 下拉选择器
  srcDataUrl: string // 查询、添加、编辑时下来选择器绑定的数据源
  allowCreate: boolean // 下拉选择器是否允许创建
  multiple: boolean // 下拉选择器是否允许多选

}

export interface TableAttr {
  columns: TableColumnInfo[]
}

export interface EditDlgData {
  key: string // 数据库字段
  label: string // 显示名称
  tag: string // html标签
  disabled: boolean // 是否允许编辑
  dataValue: object // 当前值
  filedType: string, // 字段类型
}
export interface EditDlg {
  action: string // 窗口对应的动作
  title: string // 窗口标题
  editOkBtnName: string // 确定按钮名称
  dialogWidth: string // 窗口宽度
  formLabelWidth: string // 每一行的标签宽度
  url: string // 服务器请求的api地址
  itemList: EditDlgData[] // 当前值
}
// 类型定义结束

// 列编辑区的展开行
const refEditExpandRowKeys = ref([])

// 当前列id
let columnId = 0

// 排序
const sortChange = (e: SortChangeEvent) => {
  refProp.value = e.prop
  refSortOrder.value = e.order === 'descending' ? 'desc' : 'asc'
  queryData()
}

// 改变每页数量
const handleSizeChange = (val: number) => {
  refPageSize.value = val
  queryData()
}

// 翻页
const handleCurrentChange = (val: number) => {
  refCurrentPage.value = val
  queryData()
}

// 通过改变这个值，从而重置 TableDataDlg 的显示状态
const dialogIndex = ref(0)

const rowData = ref<EditDlg>({} as EditDlg)
const refIsShowDlg = ref(false)

const isShowBtn = (event: TableItemEvent, row: object) => {
  // console.info('event', event)
  // console.info('event.isNewEvent', event.isNewEvent)
  if (event.isNewEvent) {
    return false
  }
  // console.info('event.fieldName', event.fieldName)
  // console.info('event.fieldValue', event.fieldValue)
  if (isEmptyString(event.fieldName) || isEmptyString(event.fieldValue)) {
    return true
  }
  // console.info('String(row[event.fieldName])', String(row[event.fieldName]))
  // console.info('String(event.fieldValue)', String(event.fieldValue))
  if (String(row[event.fieldName]) === String(event.fieldValue)) {
    return true
  }
  // console.info('return false')
  return false
}
// 编辑按钮
const handleEdit = (index: number, row: object, event:TableItemEvent) => {
  console.log('handleEdit enter')
  // eslint-disable-next-line
  const columns = props.tableAttr!.columns
  const itemList = [] as EditDlgData[]
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].isNewCol) {
      continue
    }
    if (!columns[i].isShowInEditDlg) {
      continue
    }
    const key = columns[i].prop
    const value = (row as {[key: string]: object})[key]
    const obj = deepCopy(columns[i])
    obj.key = key
    obj.tag = isEmptyString(columns[i].tagInEditDlg) ? 'el-input' : columns[i].tagInEditDlg
    obj.disabled = columns[i].disabledInEditDlg
    obj.dataValue = value
    itemList.push(obj)
  }
  let url = props.saveEditDataUrl
  if (!isNullValue(event)) {
    // 自定事件
    url = event.targetUrl
  } else {
    if (isEmptyString(url)) {
      // 未设置数据的保存地址，直接使用请求的地址
      url = getCommonUrl(apiPgUpdatePath)
    }
  }
  let editDlgTitle = '编辑数据'
  if (!isNullValue(event)) {
    editDlgTitle = event.editDlgTitle
  } else if (!isEmptyString(props.editDlgTitle)) {
    editDlgTitle = props.editDlgTitle
  }
  rowData.value.action = 'editData'
  rowData.value.title = editDlgTitle
  rowData.value.editOkBtnName = isNullValue(event) ? props.editOkBtnName : event.editOkBtnName
  rowData.value.dialogWidth = props.dialogWidth
  rowData.value.formLabelWidth = props.formLabelWidth
  rowData.value.url = url
  rowData.value.itemList = itemList
  refIsShowDlg.value = true
}

const addData = () => {
  // eslint-disable-next-line
  const columns = props.tableAttr!.columns
  const itemList = []
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].isNewCol) {
      continue
    }
    if (!columns[i].isShowInAddDlg) {
      continue
    }
    const obj = deepCopy(columns[i])
    obj.key = columns[i].prop
    obj.tag = isEmptyString(columns[i].tagInEditDlg) ? 'el-input' : columns[i].tagInEditDlg
    obj.disabled = false
    itemList.push(obj)
  }
  let url = props.saveNewDataUrl
  if (isEmptyString(url)) {
    url = getCommonUrl(apiPgInsertPath)
  }
  rowData.value.action = 'addData'
  rowData.value.title = isEmptyString(props.addDlgTitle) ? '添加数据' : props.addDlgTitle
  rowData.value.dialogWidth = props.dialogWidth
  rowData.value.formLabelWidth = props.formLabelWidth
  rowData.value.url = url
  rowData.value.itemList = itemList as EditDlgData[]
  refIsShowDlg.value = true
}

function onOk (dialogProps: object, data: object, response: object) {
  refIsShowDlg.value = false
  if ((response as {[success: string]: boolean}).success) {
    queryData()
  }
}
function onCancel (dialogProps: object) {
  refIsShowDlg.value = false
  console.log('onCancel')
}

// 删除按钮
const handleDelete = (index: number, row: object) => {
  let url = props.deleteDataUrl
  if (isEmptyString(url)) {
    url = getCommonUrl(apiPgDeletePath)
  }

  function deleteData (data:object) {
    // eslint-disable-next-line
    apiPostData(url, data)
      .then(data => {
        console.log('deleteData data=', data)
        showRequestResult(data, '删除', '删除失败', '删除结果')
        if ((data as {[success: string]: boolean}).success) {
          queryData()
        }
      })
  }
  if (!props.deleteDataConfirm) {
    deleteData(row)
    return
  }
  ElMessageBox.confirm(
    getI18n('你确认要删除数据吗?'),
    getI18n('注意'),
    {
      confirmButtonText: getI18n('确定'),
      cancelButtonText: getI18n('取消'),
      type: 'warning'
    }
  )
    .then(() => {
      deleteData(row)
    })
    .catch(() => {
      console.log('取消')
    })
}

export interface CustomEventData {
  id: number // 数据在表记录中对应的id
  __targetUrl: string // 按钮绑定的服务地址
  __tableName: string // 是否绑定数据窗口
  __targetSourceId: string // 是否显示确认窗口
}

// 自定义事件
const customItemEvent = (index: number, row: object, event:TableItemEvent) => {
  if (event.isShowEditDlg) {
    handleEdit(index, row, event)
    return
  }
  console.log('customItemEvent', event.targetUrl)
  let url = event.targetUrl
  if (event.targetUrl.startsWith('http://') || event.targetUrl.startsWith('https://')) {
    url = apiForwardPath
  }
  function customEventRequest (data:object) {
    apiPostData(url, data)
      .then(data => {
        console.log('customItemEvent data=', data)
        const result = showRequestResult(data, '请求成功', '')
        if (result.success) {
          queryData()
        }
      })
  }
  const data = { ...row }
  // eslint-disable-next-line
  data.__tableName = props.tableName!
  // eslint-disable-next-line
  data.__targetSourceId = props.targetSourceId!
  data.__targetUrl = event.targetUrl

  if (!event.isConfirm) {
    customEventRequest(data)
    return
  }

  ElMessageBox.confirm(
    `${getI18n('你确认要执行')}[${getI18n(event.label)}]?`,
    getI18n('注意'),
    {
      confirmButtonText: getI18n('确定'),
      cancelButtonText: getI18n('取消'),
      type: 'warning'
    }
  )
    .then(() => {
      customEventRequest(data)
    })
    .catch(() => {
      console.log('取消')
    })
}
const getCommonUrl = (baseUrl: string) => {
  if (props.tableName === undefined || !props.tableName) {
    return ''
  }
  if (props.targetSourceId === undefined || !props.targetSourceId) {
    return ''
  }
  const queryString = `__tableName=${props.tableName}&__targetSourceId=${props.targetSourceId}`
  let connector = '?'
  if (baseUrl.indexOf(connector) > 0) {
    connector = '&'
  }
  const url = `${baseUrl}${connector}${queryString}`
  return url
}
const i18nTableData = () => {
  if (!props.isI18n) {
    return
  }
  for (let i = 0; i < refTableData.value.length; i++) {
    for (const key in refTableData.value[i]) {
      const value = refTableData.value[i][key]
      refTableData.value[i][key] = getI18n(value, false)
    }
  }
}
// 向服务器请求数据
const queryData = (isExportData:boolean) => {
  console.log(`queryData props.fetchDataurl=${props.fetchDataurl},tableName=${props.tableName},targetSourceId=${props.targetSourceId}`)
  let url = ''
  let connector = '?'
  if (props.fetchDataurl === undefined || !props.fetchDataurl) {
    url = getCommonUrl(apiPgSelectPath)
    if (isEmptyString(url)) {
      console.log('queryData url is empty')
      refTableData.value = []
      refTotal.value = 0
      return
    }
  } else {
    url = props.fetchDataurl
  }

  isShowData = props.isShowData
  if (!props.isShowData && props.isEditPageMode) {
    refTableData.value = []
    refTotal.value = 0
    return
  }
  connector = '?'
  if (url.indexOf(connector) > 0) {
    connector = '&'
  }
  url = `${url}${connector}pageSize=${refPageSize.value}&currentPage=${refCurrentPage.value}&sortField=${refProp.value}&sortOrder=${refSortOrder.value}`
  for (const key in refQueryParam.value) {
    const value = refQueryParam.value[key]
    url += `&${key}=${value}`
  }
  if (isExportData) {
    url += '&__export=true'
    window.open(url, '_blank')
    return
  }
  // refTableData.value = []
  console.log('queryData url=', url)
  apiFetchData(url)
    .then(data => {
      refTableData.value = data.data
      refTotal.value = data.total
      i18nTableData()
    })
    .catch(error => console.error(error))
}

// 重置查询区
const reset = () => {
  for (const key in refQueryParam.value) {
    refQueryParam.value[key] = ''
  }
}

const getColumnLength = (): number => {
  if (props.tableAttr !== undefined && props.tableAttr.columns !== undefined) {
    return props.tableAttr.columns.length
  }
  return 0
}

// 取得列名
const getLabel = (prop: string): string => {
  for (let i = 0; i < getColumnLength(); i++) {
    if (props.tableAttr?.columns[i].prop === prop) {
      return props.tableAttr?.columns[i].label
    }
  }
  return ''
}

// 查询区按钮span的值
const getButtonSpan = (): number => {
  let span = 0
  for (let i = 0; i < getColumnLength(); i++) {
    // todo 先这样计算，为以后能后设置每一个 查询条件预留 单独的设置
    if (isTrue(props.tableAttr?.columns[i].isQuery)) {
      span += 8
    }
  }
  span = 24 - (span % 24)
  return span
}

function hasQueryArea (): boolean {
  for (let i = 0; i < getColumnLength(); i++) {
    if (isTrue(props.tableAttr?.columns[i].isQuery)) {
      return true
    }
  }
  return false
}

// 添加新的列
function addNewColumn () {
  // eslint-disable-next-line
  props.tableAttr!.columns.push({
    id: String(columnId++),
    columnType: 'normal',
    filedType: '',
    prop: '',
    label: '',
    width: '120',
    isShow: true,
    isSortable: true,
    isQuery: false,
    queryTag: 'el-input',
    isExpand: false,
    actions: {
      edit: false,
      delete: false
    },
    isNewCol: true,

    isShowInEditDlg: true,
    disabledInEditDlg: false,
    tagInEditDlg: 'el-input',
    isShowInAddDlg: true
  })
}
// 添加列
const addColumn = (index: number) => {
  // eslint-disable-next-line
  props.tableAttr!.columns[index].isNewCol = false
  addNewColumn()
}

// 删除列
const removeColumn = (index: number) => {
  // eslint-disable-next-line
  props.tableAttr!.columns.splice(index, 1)
}

// 上移
const moveUp = (index: number) => {
  if (index === 0) {
    return
  }
  const m = props.tableAttr?.columns[index - 1]
  // eslint-disable-next-line
  props.tableAttr!.columns[index - 1] = props.tableAttr!.columns[index]
  // eslint-disable-next-line
  props.tableAttr!.columns[index] = m!
}

// 下移
const moveDown = (index: number) => {
  const length = getColumnLength()
  if (index === length - 1) {
    return
  }
  const m = props.tableAttr?.columns[index + 1]
  // eslint-disable-next-line
  props.tableAttr!.columns[index + 1] = props.tableAttr!.columns[index]
  // eslint-disable-next-line
  props.tableAttr!.columns[index] = m!
}

// 重构列数据，添加缺失的字段
function restructColumns () {
  if (!props.isEditPageMode) {
    return
  }
  // eslint-disable-next-line
  let columns = props.tableAttr!.columns
  columns = columns.filter((value: TableColumnInfo) => !isTrue(value.isNewCol))
  // eslint-disable-next-line
  props.tableAttr!.columns = columns

  // 找到最大的id
  let maxColumnId = ''
  for (let i = 0; i < getColumnLength(); i++) {
    if (isNullValue(props.tableAttr?.columns[i].id)) {
      // eslint-disable-next-line
      if (maxColumnId < props.tableAttr!.columns[i].id) {
        // eslint-disable-next-line
        maxColumnId = props.tableAttr!.columns[i].id
      }
    }
  }
  if (maxColumnId !== '') {
    columnId = Number(maxColumnId)
  }

  // 修正数据
  for (let i = 0; i < getColumnLength(); i++) {
    if (isNullValue(props.tableAttr?.columns[i].id)) {
      // eslint-disable-next-line
      props.tableAttr!.columns[i].id = String(columnId++)
    }
    if (isNullValue(props.tableAttr?.columns[i].actions)) {
      // eslint-disable-next-line
      props.tableAttr!.columns[i].actions = { edit: false, delete: false }
    }
    if (isNullValue(props.tableAttr?.columns[i].isShow)) {
      // eslint-disable-next-line
      props.tableAttr!.columns[i].isShow = true
    }
    if (isNullValue(props.tableAttr?.columns[i].queryTag)) {
      // eslint-disable-next-line
      props.tableAttr!.columns[i].queryTag = 'el-input'
    }
    // eslint-disable-next-line
    if (!isEmptyString(props.tableAttr!.columns[i].columnType)) {
      if (props.tableAttr?.columns[i].columnType === 'expand') {
        continue
      } else if (props.tableAttr?.columns[i].columnType === 'action') {
        if (props.tableAttr?.columns[i].actions.edit === undefined || props.tableAttr?.columns[i].actions.edit === null) {
          // eslint-disable-next-line
          props.tableAttr!.columns[i].actions.edit = false
        }
        if (props.tableAttr?.columns[i].actions.delete === undefined || props.tableAttr?.columns[i].actions.delete === null) {
          // eslint-disable-next-line
          props.tableAttr!.columns[i].actions.delete = false
        }
      }
    } else {
      // eslint-disable-next-line
      props.tableAttr!.columns[i].columnType = 'normal'     
      if (isNullValue(props.tableAttr?.columns[i].isQuery)) {
        // eslint-disable-next-line
        props.tableAttr!.columns[i].isQuery = false
      }
      if (isNullValue(props.tableAttr?.columns[i].isExpand)) {
        // eslint-disable-next-line
        props.tableAttr!.columns[i].isExpand = false
      }
    }
  }
  addNewColumn()
}

let dataUrl = ''
let isShowData = true
watch(props, (newValue, oldValue) => {
  if (dataUrl === newValue.fetchDataurl && isShowData === props.isShowData) {
    return
  }
  dataUrl = props.fetchDataurl
  restructColumns()
  queryData()
})

// mounted时
onMounted(async () => {
  console.log('isI18n=', props.isI18n)
  console.log('onMounted props.fetchDataurl=', props.fetchDataurl)
  restructColumns()
  refPageSize.value = props.pageSizeList[0]
  try {
    queryData()
  } catch (error) {
    console.error(error)
  }
})
</script>

<style scoped>
.demo-pagination-block + .demo-pagination-block {
  margin-top: 10px;
}
.demo-pagination-block .demonstration {
  margin-bottom: 16px;
}
</style>
